/** **************************** Import Types ****************************** */
import {
    STUDENT_REQUEST,
    STUDENT_SUCCESS,
    STUDENT_FAILURE,

} from "../types/studentType";

const initialState = {
    loading: false,
    studentResponse: "",
    error: "",
};

const studentReducer = (state = initialState, action) => {
    console.log("form student api reducer", action.payload);
    switch (action.type) {
        case STUDENT_REQUEST:
            return {
                ...state,
                loading: true,
                studentResponse: "",
            };
        case STUDENT_SUCCESS:
            return {
                loading: false,
                studentResponse: action.payload.responseStatus,
                error: "",
            };
        case STUDENT_FAILURE:
            return {
                loading: false,
                studentResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default studentReducer;
