// ********import Libs ******//
import { combineReducers } from "redux";

// ******* Reducers  *****//
import { loginReducer } from "./reducer/loginReducer";
import { sideBarReducer } from "./reducer/sideBarReducer";
import { DeleteiconReducer } from "./reducer/deleteiconReducer";
import studentReducer from "./reducer/studentReducer";
import testReducer from "./reducer/testReducer";
import tutorialReducer from "./reducer/tutorialReducer";
import QbankReducer from "./reducer/qbankReducer";
import notificationReducer from "./reducer/notificationReducer";
import PaymentSettingReducer from "./reducer/paymentSettingReducer";
import NewsFeedReducer from "./reducer/newsFeedReducer";
import aboutusReducer from "./reducer/aboutusReducer";
import notesReducer from "./reducer/notesReducer";
import bannerReducer from "./reducer/bannerReducer";
import subjectReducer from "./reducer/subjectReducer";
import adminUsersReducer from "./reducer/adminUserReducer";
import QbPrimeReducer from "./reducer/qbPrimeReducer";
import ReportReducer from "./reducer/reportReducer";
import FaqReducer from "./reducer/faqReducer";
import faqGeneralReducer from "./reducer/faqGeneralReducer";
import subTopicReducer from "./reducer/subTopicReducer";
import systemReducer from "./reducer/systemReducer";
import dashBoardReducer from "./reducer/dashBoardReducer";
import { popUpReducer } from "./reducer";
import qbPrimeQuestionReducer from "./reducer/qbPrimeQuestionReducer";
import qbankQuestionReducer from "./reducer/qbankQuestionReducer";
import leaderBoardReducer from "./reducer/leaderBoardReducer";
import userSubscriptionReducer from "./reducer/userSubscriptionReducer";
import primeUserSubscriptionReducer from "./reducer/primeUserSubscriptionreducer";
import QbReportReducer from "./reducer/qbReportReducer";

const rootReducer = combineReducers({
  Login: loginReducer,
  SideBar: sideBarReducer,
  deleteicon: DeleteiconReducer,
  admin: adminUsersReducer,
  student: studentReducer,
  subject: subjectReducer,
  qbank: QbankReducer,
  paymentSetting: PaymentSettingReducer,
  newsFeed: NewsFeedReducer,
  Notification: notificationReducer,
  test: testReducer,
  tutorial: tutorialReducer,
  aboutus: aboutusReducer,
  notes: notesReducer,
  banner: bannerReducer,
  report: ReportReducer,
  qbreport: QbReportReducer,
  qbPrime: QbPrimeReducer,
  Faq: FaqReducer,
  FaqGeneral: faqGeneralReducer,
  subTopic: subTopicReducer,
  system: systemReducer,
  dashBoard: dashBoardReducer,
  popUp: popUpReducer,
  qbprimeQuestion: qbPrimeQuestionReducer,
  qbankQuestion: qbankQuestionReducer,
  leaderBoard: leaderBoardReducer,
  userSubscription: userSubscriptionReducer,
  primeusersub: primeUserSubscriptionReducer,
});

export default rootReducer;
