/** **************************** Import Types ****************************** */
import { LEADERBOARD_SUCCESS, LEADERBOARD_REQUEST, LEADERBOARD_FAILURE } from "../types";

const initialState = {
    loading: false,
    leaderBoardResponse: "",
    error: "",
};

const faqReducer = (state = initialState, action) => {
    console.log("faqq", action.payload);
    switch (action.type) {
        case LEADERBOARD_REQUEST:
            return {
                ...state,
                loading: true,
                leaderBoardResponse: "",
            };
        case LEADERBOARD_SUCCESS:
            return {
                loading: false,
                leaderBoardResponse: action.payload.responseStatus,
                error: "",
            };
        case LEADERBOARD_FAILURE:
            return {
                loading: false,
                leaderBoardResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default faqReducer;
