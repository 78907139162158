/** ****************************** Import libs *********************************** */

const URL_CONSTANTS = {
  login: "admin-user/login",
  logout: "logout",
  adminusers: "admin-user",
  studentusers: "admin-user/student-list",
  testlist: "admin-user/test-items",
  tutoriallist: "admin-user/tutorial-items",
  banner: "admin-user/banner",
  newsfeed: "admin-user/news-feed",
  aboutus: "admin-user/about-us",
  notes: "admin-user/mynotes",
  // gettoken: "get-token",
  products: "products",
  categories: "categories",
  paymentsetting: "admin-user/payment",
  qBank: "admin-user/question-bank-history",
  qBankCreate: "admin-user/question-bank",
  question: "admin-user/question",
  report: "admin-user/tutorial-test-report",
  qbreport: "admin-user/prime-test-report",
  download: "admin-user/question_bank/download",
  student: "admin-user/student",
  qbPrime: "admin-user/prime-question",
  qbCreate: "admin-user/prime-question-bank",
  allQuestion: "admin-user/prime-all-question",
  qbHistory: "admin-user/prime-question-bank-history",
  faq: "admin-user/prime-faq",
  faqs: "admin-user/faq",
  notification: "admin-user/notification",
  download_qbprime: "admin-user/prime-question-bank/download",
  subjectlist: "admin-user/subject-list",
  allsubject: "admin-user/all-subject-list",
  // subtopicbysub: "admin-user/subject-subtopic",
  subjectSubTopicId: "admin-user/subject-subtopic",
  subTopic: "admin-user/subtopic",
  system: "admin-user/system",
  dashboard: "admin-user/dashboard",
  //download
  downloadQbank: "admin-user/all-question_bank/download",
  downloadQbprime: "admin-user/prime-all-question_bank/download",
  leaderboard: "admin-user/prime-test-rank",
  //search
  qBankSearch: "admin-user/question-search",
  qbprimeBankSearch: "admin-user/prime-question-search",
  studentSearch: "admin-user/student-user-search",
  adminSearch: "admin-user/admin-user-search",
  //subscription
  userSubscription: "admin-user/user-subscription",
  primeUserSubscription: "admin-user/prime-user-subscription",
};

export { URL_CONSTANTS };
