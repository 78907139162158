/** ****************************** Import libs *********************************** */
import { postDataApi, postDataByIdApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const postLoginRequestData = (params) =>
  postDataApi(URL_CONSTANTS.login, params);

export const postLogoutRequestData = (params) =>
  postDataApi(URL_CONSTANTS.logout, params);

export const postUserData = (params) =>
  postDataApi(URL_CONSTANTS.users, params);

// export const getToken = (params) => postDataApi(URL_CONSTANTS.gettoken, params);

// post student details
export const postAdminUsersData = (params) =>
  postDataApi(URL_CONSTANTS.adminusers, params);
export const postStudentData = (params) =>
  postDataApi(URL_CONSTANTS.studentusers, params);
export const postSubjectData = (params) =>
  postDataApi(URL_CONSTANTS.subjectlist, params);
//post notification data
export const postNotificationData = (params) =>
  postDataApi(URL_CONSTANTS.notification, params);
//post Test Data
export const postTestData = (params) =>
  postDataApi(URL_CONSTANTS.testlist, params);
//post banner data
export const postBannerData = (params) =>
  postDataApi(URL_CONSTANTS.banner, params);
//post payment setting data
export const postPaymentSettingData = (params) =>
  postDataApi(URL_CONSTANTS.paymentsetting, params);
//post aboutus data
export const postAboutusData = (params) =>
  postDataApi(URL_CONSTANTS.aboutus, params);
//notes aboutus data
export const postNotesData = (params) =>
  postDataApi(URL_CONSTANTS.notes, params);
//post newsfeed data
export const postNewsFeedData = (params) =>
  postDataApi(URL_CONSTANTS.newsfeed, params);
//post qbank data
export const postQbankData = (params) =>
  postDataApi(URL_CONSTANTS.qBankCreate, params);
//post qbank question data
export const postQbankQuestionData = (params, id) =>
  postDataByIdApi(URL_CONSTANTS.question, params, id);
//post qb data
export const postQbData = (params) =>
  postDataApi(URL_CONSTANTS.qbCreate, params);
//post prime-faq data
export const postFaq = (params) => postDataApi(URL_CONSTANTS.faq, params);
//post faq data
export const postFaqGeneral = (params) => postDataApi(URL_CONSTANTS.faqs, params);
//subTopic
export const postSubTopic = (params) =>
  postDataApi(URL_CONSTANTS.subTopic, params);
//system
export const postSystem = (params) =>
  postDataApi(URL_CONSTANTS.system, params); 
