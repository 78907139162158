/** **************************** Import Types ****************************** */
import {
    REPORT_REQUEST,
    REPORT_SUCCESS,
    REPORT_FALIURE,

} from "../types/reportTypes";

const initialState = {
    loading: false,
    ReportResponse: "",
    error: "",
};

const ReportReducer = (state = initialState, action) => {
    console.log("form student api reducer", action.payload);
    switch (action.type) {
        case REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                ReportResponse: "",
            };
        case REPORT_SUCCESS:
            return {
                loading: false,
                ReportResponse: action.payload.responseStatus,
                error: "",
            };
        case REPORT_FALIURE:
            return {
                loading: false,
                ReportResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default ReportReducer;
