import React from "react";
import TableRow from "./TableRow";
import TableHeadItem from "./TableHead";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
// import TableItem from "./TableItem";
import "../Pagination/pagination.css";
import Preloader from "../Preloader";
import { FiPlus } from "react-icons/fi";
import "./table.css";
import SearchBar from "../Searchbar/SearchBar";
import TableTop from "../Table-top/table-top";
// import Pagination from "../F-Pagination/pagination";

const Tables = ({
  theadData,
  tbodyData,
  ticonData,
  tsearchabar,
  pagination,
  rowsPerPageOption,
  Action,
  button,
  onClickRow,
  currentPages,
  rowsPerPage,
  handleAddButton,
  handleEdit,
  handleDelete,
  handleDownload,
  handleCopy,
  noItemsViewSlot,
  activeStatus,
  ...others
}) => {
  {
    let options = null;
    if (rowsPerPageOption) {
      options = rowsPerPageOption.map((data) => <option>{data}</option>);
    }

    // console.log(theadData, "head");
    // const [currentPage, setCurrentPage] = React.useState(1);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // const totalRecords = tbodyData ? tbodyData.length : 0;
    // const indexOfLastData = currentPage * rowsPerPage;
    // const indexOfFirstData = indexOfLastData - rowsPerPage;
    const currentData = tbodyData;
    // console.log(tbodyData, "tbodyData");
    // ? tbodyData.slice(indexOfFirstData, indexOfLastData)
    // : 0;
    // const totalPage = Math.ceil(totalRecords / rowsPerPage);
    // const name = {
    //   name: "items"
    // }

    return (
      <div>
        {/* <TableTop/> */}
        <div className="container-fluid">
          <table className="table table-hover text-center table-responsive card ">
            <thead className="table-head-bgcolor ">
              {theadData?.map((h) => {
                return <TableHeadItem key={h} item={h} />;
              })}
            </thead>
            {/* <div className="ms-4 me-4 table-body-border"> */}
            <tbody className="tab-red">
              {tbodyData?.length > 0 ? (
                tbodyData?.map((item, index) => {
                  return (
                    <>
                      <TableRow
                        key={index}
                        data={item}
                        theadData={theadData}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        onClickRow={onClickRow}
                        handleDownload={handleDownload}
                        handleCopy={handleCopy}
                        activeStatus={activeStatus}
                      />
                      {/* <hr className="hr-bottom"/> */}
                    </>
                  );
                })
              ) : (
                <div>{noItemsViewSlot}</div>
              )}
            </tbody>
            {/* </div> */}
          </table>
        </div>
        {/* <Pagination /> */}
        {/* <div className="container-fluid pagination-container">
          <div className="d-flex pl-3">
            <div className="" style={{}}>
              Per page&nbsp;
              <select
                id="role"
                className=""
                {...others}
                onChange={(e) => {
                  changeRowsPerPage(e.target.value);
                }}
                value={rowsPerPage}
              >
                {options ? (
                  options
                ) : (
                  <>
                    <option>10 items</option>
                    <option>15 items</option>
                    <option>20 items</option>
                  </>
                )}
              </select>
              &nbsp;
            </div>
            <ul className="pagination-bottom-card">
              <li className="currentPage">Page {currentPage}</li>
              <span>of</span>
              <li className="totalPage">{totalPage}</li></ul>
            <ul className=""> <li
              className={currentPage > 1 ? "pagination ml-1" : "disabled ml-1"}
              onClick={() => previousPage()}
            >

              <span className="customSpan card">
                <AiOutlineLeft />
              </span>
            </li>
              <li
                className={
                  currentPage < totalPage ? "pagination ml-1" : "disabled ml-1"
                }
                onClick={() => nextpage()}
              >
                <span className="customSpan card">
                  <AiOutlineRight />
                </span>
              </li>
            </ul>

          </div>
        </div> */}
      </div>
    );
  }
};

export default Tables;
