/** **************************** Import Types ****************************** */
import {
  QBANKQUESTION_REQUEST,
  QBANKQUESTION_SUCCESS,
  QBANKQUESTION_FAILURE,
} from "../types/qbankQuestionTypes";

const initialState = {
  loading: false,
  qbankQuestionResponse: "",
  error: "",
};

const qbankQuestionReducer = (state = initialState, action) => {
  console.log(action.payload, "qbankquestionReducer");
  switch (action.type) {
    case QBANKQUESTION_REQUEST:
      return {
        ...state,
        loading: true,
        qbankQuestionResponse: "",
      };
    case QBANKQUESTION_SUCCESS:
      return {
        ...state,
        qbankQuestionResponse: action.payload,
        loading: false,
      };
    case QBANKQUESTION_FAILURE:
      return {
        ...state,
        qbankQuestionResponse: "error",
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default qbankQuestionReducer;
