/** **************************** Import Types ****************************** */
import {
    PAYMENTSETTING_REQUEST,
    PAYMENTSETTING_SUCCESS,
    PAYMENTSETTING_FAILURE,

} from "../types/paymentSettingType";

const initialState = {
    loading: false,
    paymentSettingResponse: "",
    error: "",
};

const PaymentSettingReducer = (state = initialState, action) => {
    console.log("form PaymentSetting api reducer", action.payload);
    switch (action.type) {
        case PAYMENTSETTING_REQUEST:
            return {
                ...state,
                loading: true,
                paymentSettingResponse: "",
            };
        case PAYMENTSETTING_SUCCESS:
            return {
                loading: false,
                paymentSettingResponse: action.payload.responseStatus,
                error: "",
            };
        case PAYMENTSETTING_FAILURE:
            return {
                loading: false,
                paymentSettingResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default PaymentSettingReducer;
