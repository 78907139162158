import React, { useEffect, useState } from "react";
import "../css/signin.css";
import "../assets/css/login.css";
import { MdCopyright } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import loginImg from "../assets/images/sign-in.svg";
import { Formik } from "formik";
import { LoginUser } from "../redux/action/loginAction";
import { connect } from "react-redux";
import GitInfo from "react-git-info/macro";

const Login = (props) => {
  // ========================================GET token===========================//
  const initialValues = {
    email_id: "",
    password: "",
  };
  const [passwordShown, setPasswordShown] = useState(false);

  // const notify = () => toast("Wow so easy!");
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email_id) {
      errors.email_id = "Email is required";
    } else if (!regex.test(values.email_id)) {
      errors.email_id = "Invalid Email";
    }

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password too short";
    }

    return errors;
  };

  const submitForm = (values) => {
    props.LoginUser(values);
  };
  useEffect(() => {
    let token = localStorage.getItem("token" && "name") || null;

    if (token == null) {
      // window.location.href = "/";
    } else if (token) {
      window.location.href = "/dashboard";
    } else {
      window.location.href = "/login";
    }
  }, []);
  const userName = localStorage.getItem("name");

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={submitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          dirty,
        } = formik;
        return (
          <div className="form-sign">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className="login-img">
                  <img src={loginImg} alt="login" width="100%" />
                </div>
              </div>
              <div className="col-md-4 container-1">
                <div className="container-2">
                  <div className="header-1">
                    <h1>QBace</h1>
                    <h4>Admin System</h4>
                  </div>

                  <form onSubmit={handleSubmit} className="form-container mt-4">
                    <div className="form-row">
                      <label htmlFor="email_id" className="lablelog">
                        Email
                      </label>
                      <input
                        autoFocus
                        type="email_id"
                        name="email_id"
                        id="email_id"
                        value={values.email_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email_id && touched.email_id
                            ? "input-error"
                            : " login-input"
                        }
                      />
                      {errors.email_id && touched.email_id && (
                        <span className="text-danger">{errors.email_id}</span>
                      )}
                    </div>

                    <div className="form-row ">
                      <label htmlFor="password" className="lablelog">
                        Password
                      </label>
                      <div>
                        <input
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          id="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.password && touched.password
                              ? "input-error"
                              : "login-input"
                          }
                        />
                      </div>
                      {/* <i
                  className={ `fa ${
                    passwordShown ? "fa-eye" : "fa-eye-slash"
                  } password-icon` }
                  onClick={ () => setPasswordShown(!passwordShown) }
                /> */}

                      {errors.password && touched.password && (
                        <span className="text-danger">{errors.password}</span>
                      )}
                      <div
                        onClick={() => setPasswordShown(!passwordShown)}
                        className="password-icon"
                      >
                        {passwordShown ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </div>
                    </div>
                    {/* <Link to='/dashboard' > */}
                    <button
                      type="submit"
                      className={
                        !(dirty && isValid) ? "disabled-btn-1" : "button-1"
                      }
                      disabled={!(dirty && isValid)}
                    >
                      Sign In
                    </button>

                    {/* </Link> */}
                    {/* <div className="forgot">Forgot Password ?</div> */}
                  </form>
                </div>
                {/* <div style={{ width: "90%", marginLeft: "36px" }}>
                  <p className="mt-3">
                    <span style={{ fontWeight: "bold" }}>Commit Id:</span>
                    {GitInfo().commit.hash}
                  </p>
                  <p className="mt-3">
                    <span style={{ fontWeight: "bold" }}>Message:</span>
                    {GitInfo().commit.message}
                  </p>
                  <p className="mt-3">
                    <span style={{ fontWeight: "bold" }}>Date:</span>
                    {GitInfo().commit.date.split("T")[0]}
                  </p>
                </div> */}
              </div>

              <div className="footer-login ">
                <MdCopyright className="copy-icon" />
                &nbsp;
                <p className="copyright-bottom">
                  {" "}
                  2022 QBace. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  loginResponse: state.Login.storeLoginResponse,
  loginError: state.Login.error,
  // preLoader: state.login.loading,
});
const mapDispatchToProps = (dispatch) => ({
  LoginUser: (data) => dispatch(LoginUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
