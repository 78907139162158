/** **************************** Import Types ****************************** */
import {
    TEST_REQUEST,
    TEST_SUCCESS,
    TEST_FAILURE,

} from "../types/testTypes";

const initialState = {
    loading: false,
    testResponse: "",
    error: "",
};

const testReducer = (state = initialState, action) => {

    switch (action.type) {
        case TEST_REQUEST:
            return {
                ...state,
                loading: true,
                testResponse: "",
            };
        case TEST_SUCCESS:
            return {
                loading: false,
                testResponse: action.payload.responseStatus,
                error: "",
            };
        case TEST_FAILURE:
            return {
                loading: false,
                testResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default testReducer;
