/** **************************** Import Types ****************************** */
import {
  PRIME_USER_SUBSCRIPTION_SUCCESS,
  PRIME_USER_SUBSCRIPTION_REQUEST,
  PRIME_USER_SUBSCRIPTION_FAILURE,
} from "../types/primeUserSubscriptionTypes";
const initialState = {
  loading: false,
  primeUserSubscriptionResponse: "",
  error: "",
};
const primeUserSubscriptionReducer = (state = initialState, action) => {
  //   console.log("form Tutorial api reducer", action.payload);
  switch (action.type) {
    case PRIME_USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        primeUserSubscriptionResponse: "",
      };
    case PRIME_USER_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        primeUserSubscriptionResponse: action.payload.responseStatus,
        error: "",
      };
    case PRIME_USER_SUBSCRIPTION_FAILURE:
      return {
        loading: false,
        primeUserSubscriptionResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default primeUserSubscriptionReducer;
