/** **************************** Import Types ****************************** */
import {
    ADMIN_USERS_REQUEST,
    ADMIN_USERS_SUCCESS,
    ADMIN_USERS_FAILURE,

} from "../types/adminUserTypes";

const initialState = {
    loading: false,
    adminUsersResponse: "",
    error: "",
};

const adminUsersReducer = (state = initialState, action) => {
    console.log("form Adminusers api reducer----", action.payload);
    switch (action.type) {
        case ADMIN_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                adminUsersResponse: "",
            };
        case ADMIN_USERS_SUCCESS:
            return {
                loading: false,
                adminUsersResponse: action.payload.responseStatus,
                error: "",
            };
        case ADMIN_USERS_FAILURE:
            return {
                loading: false,
                adminUsersResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default adminUsersReducer;
