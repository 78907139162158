/** **************************** Import Types ****************************** */
import {
  USER_SUBSCRIPTION_SUCCESS,
  USER_SUBSCRIPTION_REQUEST,
  USER_SUBSCRIPTION_FAILURE,
} from "../types/userSubscriptiontypes";

const initialState = {
  loading: false,
  userSubscriptionResponse: "",
  error: "",
};

const userSubscriptionReducer = (state = initialState, action) => {
  //   console.log("form Tutorial api reducer", action.payload);
  switch (action.type) {
    case USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        userSubscriptionResponse: "",
      };
    case USER_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        userSubscriptionResponse: action.payload.responseStatus,
        error: "",
      };
    case USER_SUBSCRIPTION_FAILURE:
      return {
        loading: false,
        userSubscriptionResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userSubscriptionReducer;
