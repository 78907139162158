import React from "react";
import { BiSort } from "react-icons/bi";

const TableHeadItem = ({ item }) => {
  // console.log("tableHead-----", item);

  return (
    <>
      {item === "Action" ? (
        <th title={item} className="table-header text-center">
          {item} &nbsp;
          {/* <BiSort className="sort-icon"/> */}
        </th>
      ) : (
        <th title={item} className="table-header">
          {item} &nbsp;
          {/* <BiSort className="sort-icon"/> */}
        </th>
      )}
    </>
  );
};

export default TableHeadItem;

{
  /* <BiSort /> */
}
