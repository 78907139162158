/** **************************** Import Types ****************************** */
import { FAQ_SUCCESS, FAQ_REQUEST, FAQ_FAILURE } from "../types";

const initialState = {
    loading: false,
    faqResponse: "",
    error: "",
};

const faqReducer = (state = initialState, action) => {
    console.log("faqq", action.payload);
    switch (action.type) {
        case FAQ_REQUEST:
            return {
                ...state,
                loading: true,
                faqResponse: "",
            };
        case FAQ_SUCCESS:
            return {
                loading: false,
                faqResponse: action.payload.responseStatus,
                error: "",
            };
        case FAQ_FAILURE:
            return {
                loading: false,
                faqResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default faqReducer;
