/** **************************** Import Types ****************************** */
import {
    QBPRIMEQUESTION_REQUEST,
    QBPRIMEQUESTION_SUCCESS,
    QBPRIMEQUESTION_FAILURE,

} from "../types/qbprimeQuestionTypes";

const initialState = {
    loading: false,
    qbPrimeQuestionResponse: "",
    error: "",
};

const qbPrimeQuestionReducer = (state = initialState, action) => {
    switch (action.type) {
        case QBPRIMEQUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                qbPrimeQuestionResponse: "",
            };
        case QBPRIMEQUESTION_SUCCESS:
            return {
                loading: false,
                qbPrimeQuestionResponse: action.payload,
                error: "",
            };
        case QBPRIMEQUESTION_FAILURE:
            return {
                loading: false,
                qbPrimeQuestionResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default qbPrimeQuestionReducer;
