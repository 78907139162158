/** **************************** Import Types ****************************** */
import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
} from "../types/dashBoardTypes";

const initialState = {
  loading: false,
  dashBoardResponse: "",
  error: "",
};

const dashBoardReducer = (state = initialState, action) => {
  console.log("faqq", action.payload);
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        dashBoardResponse: "",
      };
    case DASHBOARD_SUCCESS:
      return {
        loading: false,
        dashBoardResponse: action.payload.responseStatus,
        error: "",
      };
    case DASHBOARD_FAILURE:
      return {
        loading: false,
        dashBoardResponse: "error",
        error: action.payload,
      };

    default:
      return state;
  }
};

export default dashBoardReducer;
