import React, { useEffect, useState } from "react";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { BsDownload, BsFileEarmarkCheck } from "react-icons/bs";
import { AiOutlineCopy } from "react-icons/ai";
const TableRow = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    key,
    handleEdit,
    handleDelete,
    onClickRow,
    handleCopy,
    handleDownload,
    theadData,
    handleClone,
    copy,
    activeStatus,
  } = props;

  const newData = data;
  // console.log(newData, "datas");
  const newData1 = data;
  const [lastTable, setLastTable] = useState();
  return (
    <>
      <tr className="tdata-row">
        {theadData?.map((item, index) => {
          // console.log(item, "conslod");
          return (
            <>
              {item !== "Action" ? (
                <td
                  key={index}
                  className="table-data"
                  onClick={() => (onClickRow ? onClickRow(data) : null)}
                >
                  {newData?.image && item === "image" ? (
                    <img src={newData[item]} width="50px" />
                  ) : item == "Status" ? (
                    <td
                      className="table-data p-0 table-data-1"
                      // onClick={() => (onClickRow ? onClickRow(data) : null)}
                    >
                      <div>
                        {newData[item] === 1 ? (
                          <p className="active-status">Active</p>
                        ) : (
                          <p className="in-active-status">In-Active</p>
                        )}
                      </div>
                    </td>
                  ) : (
                    newData[item]
                  )}
                </td>
              ) : null}
            </>
          );
        })}
        {/* {newData?.map((item, index) => (
          <>
            {console.log(item[0]["status"], "---------------")}
            {item[0] === "image" ? (
              <td
                className="table-data"
                onClick={() => (onClickRow ? onClickRow(data) : null)}
              >
                <img
                  src={"image" === item[1] ? item[1] : ""}
                  alt=""
                  width="25px"
                  height="25px"
                />
              </td>
            ) : item[0] === "status" ? (
              <td
                className="table-data"
                onClick={() => (onClickRow ? onClickRow(data) : null)}
              >
                {item[1] === 1 ? (
                  <p className="active-status">Active</p>
                ) : (
                  <p className="in-active-status">In-Active</p>
                )}
              </td>
            ) : (
              <td
                className="table-data"
                onClick={() => (onClickRow ? onClickRow(data) : null)}
              >
                {item[1]}
              </td>
            )}
          </>
        ))} */}
        {handleEdit ||
        handleDelete ||
        handleDownload ||
        handleClone ||
        handleCopy ? (
          <td className="table-data">
            <div className="action-tab">
              {handleCopy ? (
                <div className="cursor-pointer">
                  <AiOutlineCopy onClick={() => handleCopy(data)} />
                </div>
              ) : null}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {handleEdit ? (
                <div className="cursor-pointer">
                  <FiEdit2 onClick={() => handleEdit(data)} />
                </div>
              ) : null}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {handleDelete ? (
                <div className="cursor-pointer">
                  <FiTrash onClick={() => handleDelete(data)} />
                </div>
              ) : null}
              {handleClone ? (
                <div className="cursor-pointer">
                  <BsFileEarmarkCheck onClick={() => handleClone(data)} />
                </div>
              ) : null}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {handleDownload ? (
                <div className="cursor-pointer">
                  <BsDownload onClick={() => handleDownload(data)} />
                </div>
              ) : null}
            </div>
          </td>
        ) : null}
      </tr>
    </>
  );
};

export default TableRow;
