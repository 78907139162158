/** **************************** Import Types ****************************** */
import {
    NOTIFICATION_REQUEST,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_FAILURE,

} from "../types/notificationType";

const initialState = {
    loading: false,
    notificationResponse: "",
    error: "",
};

const NotificationReducer = (state = initialState, action) => {
    console.log("form Notification api reducer", action.payload);
    switch (action.type) {
        case NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                notificationResponse: "",
            };
        case NOTIFICATION_SUCCESS:
            return {
                loading: false,
                notificationResponse: action.payload.responseStatus,
                error: "",
            };
        case NOTIFICATION_FAILURE:
            return {
                loading: false,
                notificationResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default NotificationReducer;
