/** **************************** Import Types ****************************** */
import {
    QBANK_REQUEST,
    QBANK_SUCCESS,
    QBANK_FAILURE,

} from "../types/qbankType";

const initialState = {
    loading: false,
    qbankResponse: "",
    error: "",
};

const QbankReducer = (state = initialState, action) => {
    console.log("form Qbank api reducer", action.payload);
    switch (action.type) {
        case QBANK_REQUEST:
            return {
                ...state,
                loading: true,
                qbankResponse: "",
            };
        case QBANK_SUCCESS:
            return {
                ...state,
                qbankResponse: action.payload,
                loading: false,
            };
        case QBANK_FAILURE:
            return {
                ...state,
                qbankResponse: "error",
                error: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default QbankReducer;
