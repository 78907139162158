/** **************************** Import Types ****************************** */
import {
    SUBJECT_REQUEST,
    SUBJECT_SUCCESS,
    SUBJECT_FAILURE,

} from "../types/subjectType";

const initialState = {
    loading: false,
    subjectResponse: "",
    error: "",
};

const subjectReducer = (state = initialState, action) => {
    console.log("form student api reducer", action.payload);
    switch (action.type) {
        case SUBJECT_REQUEST:
            return {
                ...state,
                loading: true,
                subjectResponse: "",
            };
        case SUBJECT_SUCCESS:
            return {
                loading: false,
                subjectResponse: action.payload.responseStatus,
                error: "",
            };
        case SUBJECT_FAILURE:
            return {
                loading: false,
                subjectResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default subjectReducer;
