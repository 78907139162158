import { POPUP_OPEN, POPUP_CLOSE } from "../types/popUpTypes";
const initialState = {
    popUpOpen: false,
};
export const popUpReducer = (state = initialState, action) => {
    console.log(action, "Im opner");
    switch (action.type) {
        case POPUP_OPEN:
            return {
                ...state,
                popUpOpen: action.payload
            };
            case POPUP_CLOSE:
            return {
                ...state,
                popUpOpen:action.payload
                
            };
        default:
            return state;
    }
}