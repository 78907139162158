import React from "react";
// import Table from "../components/Table/Table";
// import Pagination from "../components/Pagination/Pagination";
// import Sidebar from "../components/Sidebar/Sidebar";
import * as AiIcons from "react-icons/ai";
// import SearchBar from "../components/Searchbar/searchBar";
import SearchBar from "../components/Searchbar/SearchBar";
import Tables from "../components/Table_Component/Table";

const TableData = (props) => {
  const theadData = [
    "Name",
    "Company",
    "Email",
    "Phone",
    "City",
    "Order",
  ];
  const ticonData = [<AiIcons.AiFillHome />];
  const tsearchabar = [<AiIcons.AiOutlineMonitor />];
  const tbodyData =
    // data.map(name =>[name.athlete,name.age, name.country, name.total] )
    [
      {
        id: "1",
        items: [
          "John",
          "Applogiq",
          "Vishal@gmail.com",
          "1234567890",
          "Coimbatore",
          "1",

        ],
      },
      {
        id: "2",
        items: [
          "Sally",
          "Applogiq",
          "praveen@gmail.com",
          "1234567890",
          "Chennai",
          "2",
        ],
      },
      {
        id: "3",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "3",
        ],
      },
      {
        id: "4",
        items: [
          "John",
          "Applogiq",
          "Vishal@gmail.com",
          "1234567890",
          "Coimbatore",
          "4",
        ],
      },
      {
        id: "5",
        items: [
          "Sally",
          "Applogiq",
          "praveen@gmail.com",
          "1234567890",
          "Chennai",
          "5",
        ],
      },
      {
        id: "6",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "6",
        ],
      },
      {
        id: "7",
        items: [
          "John",
          "Applogiq",
          "Vishal@gmail.com",
          "1234567890",
          "Coimbatore",
          "7",
        ],
      },
      {
        id: "8",
        items: [
          "Sally",
          "Applogiq",
          "praveen@gmail.com",
          "1234567890",
          "Chennai",
          "8",
        ],
      },
      {
        id: "9",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "9",
        ],
      },
      {
        id: "10",
        items: [
          "John",
          "Applogiq",
          "Vishal@gmail.com",
          "1234567890",
          "Coimbatore",
          "10",
        ],
      },
      {
        id: "11",
        items: [
          "Sally",
          "Applogiq",
          "praveen@gmail.com",
          "1234567890",
          "Chennai",
          "11",
        ],
      },
      {
        id: "12",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "12",
        ],
      },
      {
        id: "13",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "13",
        ],
      },
      {
        id: "14",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "14",
        ],
      },
      {
        id: "15",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "15",
        ],
      },
      {
        id: "16",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "16",
        ],
      },
      {
        id: "17",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "17",
        ],
      },
      {
        id: "18",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "18",
        ],
      },
      {
        id: "19",
        items: [
          "Maria",
          "Applogiq",
          "applogiq@gmail.com",
          "1234567890",
          "Tirupur",
          "19",
        ],
      },
    ];

  const rowPerPageOptions = [10, 20, 30];
  // const { pages, nextPage, currentPage, changeRowsPerPage, rowsPerPage } = props;

  return (
    <>
      <div style={{ backgroundColor: "white", borderRadius: "10px" }} className="mt-3">

        <div className="row container-fluid">
          <div className="col-md-6">
            <h3 className="pt-3">Data Table</h3>
          </div>
          <div className="col-md-6 search-bar-input ">
            {/* <SearchBar /> */}
            <div>
              {/* <img src={Addicon} className="add-icon" /> */}
            </div>
          </div>
        </div>

        <hr />
        <Tables
          pagination
          theadData={theadData}
          tbodyData={tbodyData}
          ticonData={ticonData}
          tsearchabar={tsearchabar}
          rowsPerPage={10}
          currentPage={1}
          Action={<button className="btn btn-primary">Inprogess</button>}
          rowPerPageOption={rowPerPageOptions}
        />

        {
          //   data.map((item) => (
          // <div className="user">{item.pages}</div>
          // <Pagination
          //   // pages={1}
          //   // nextPage={10}
          //   // currentPage={1}
          //   // changeRowsPerPage={10}
          //   // rowsPerPage={5}
          //   data={tbodyData}
          //   pages={item.pages}
          //   nextPage={item.nextPage}
          //   currentPage={item.currentPage}
          //   changeRowsPerPage={item.changeRowsPerPage}
          //   // rowsPerPageOption={rowPerPageOptions}
          //   rowsPerPage={item.rowsPerPage}
          // />
          //       ))
        }
        {/* <Pagination
        // pages={1}
        // nextPage={10}
        // currentPage={1}
        // changeRowsPerPage={10}
        // rowsPerPage={5}
        pages={props.pages}
        nextPage={props.nextPage}
        currentPage={props.currentPage}
        changeRowsPerPage={props.changeRowsPerPage}
        rowsPerPage={props.rowsPerPage}
      /> */}
      </div>
    </>
  );
};

export default TableData;
