/** **************************** Import Types ****************************** */
import {
    NOTES_REQUEST,
    NOTES_SUCCESS,
    NOTES_FAILURE,

} from "../types/notesTypes";

const initialState = {
    loading: false,
    notesResponse: "",
    error: "",
};

const notesReducer = (state = initialState, action) => {
    console.log("form notes api reducer", action.payload);
    switch (action.type) {
        case NOTES_REQUEST:
            return {
                ...state,
                loading: true,
                notesResponse: "",
            };
        case NOTES_SUCCESS:
            return {
                loading: false,
                notesResponse: action.payload.responseStatus,
                error: "",
            };
        case NOTES_FAILURE:
            return {
                loading: false,
                notesResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default notesReducer;
