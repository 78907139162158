import React from 'react';
/** ***************************** Import Preloader ******************************** */
import Loader from "../../assets/images/Preloader.gif";
const Preloader = () => {
  return (
    <div className="d-flex justify-content-center">
      <div
        style={{
          position: "absolute",
          top: "38%",
          left: "43%",
          zIndex: "2",
        }}
      >
        <img
          src={Loader}
          alt="Preloader"
          className="img-fluid"
          width="200px"
          height="200px"
        />
      </div>
    </div>
  )
}

export default Preloader;