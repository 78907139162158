/** **************************** Import Types ****************************** */
import {
    QB_REQUEST,
    QB_SUCCESS,
    QB_FAILURE,

} from "../types/qbPrimeTypes";

const initialState = {
    loading: false,
    qbPrimeResponse: "",
    error: "",
};

const qbPrimeReducer = (state = initialState, action) => {
    console.log("form notes api reducer", action.payload);
    switch (action.type) {
        case QB_REQUEST:
            return {
                ...state,
                loading: true,
                qbPrimeResponse: "",
            };
        case QB_SUCCESS:
            return {
                loading: false,
                qbPrimeResponse: action.payload.responseStatus,
                error: "",
            };
        case QB_FAILURE:
            return {
                loading: false,
                qbPrimeResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default qbPrimeReducer;
