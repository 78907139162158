/** **************************** Import Types ****************************** */
import {
    QBREPORT_REQUEST,
    QBREPORT_SUCCESS,
    QBREPORT_FALIURE,

} from "../types/qbreportTypes";

const initialState = {
    loading: false,
    QbReportResponse: "",
    error: "",
};

const QbReportReducer = (state = initialState, action) => {
    console.log("form student api reducer", action.payload);
    switch (action.type) {
        case QBREPORT_REQUEST:
            return {
                ...state,
                loading: true,
                QbReportResponse: "",
            };
        case QBREPORT_SUCCESS:
            return {
                loading: false,
                QbReportResponse: action.payload.responseStatus,
                error: "",
            };
        case QBREPORT_FALIURE:
            return {
                loading: false,
                QbReportResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default QbReportReducer;
