import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postLoginRequestData } from "../../api/create";
import {
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
  LOGIN_USERS_FALIURE,
} from "../types";



export const LoginUsersRequest = () => ({
  type: LOGIN_USERS_REQUEST
})
export const LoginUsersSuccess = (users) => ({
  type: LOGIN_USERS_SUCCESS,
  payload: users
});

export const LoginUsersFailure = (error) => ({
  type: LOGIN_USERS_FALIURE,
  payload: error
})

export const LoginUser = (data) =>
  async function (dispatch) {
    dispatch(LoginUsersRequest());
    await postLoginRequestData(data)
      // ============================

      .then((res) => {
        if (res) {
          const loggedUser = res?.records?.[0];
          const token = res.token.access_token
          localStorage.setItem("loggedUsers", JSON.stringify(loggedUser));
          localStorage.setItem("token", token);

          dispatch(LoginUsersSuccess({ responseStatus: "success" }));
          toast.success("Logged in Successfully");
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 3000);

        } else {
          dispatch(LoginUsersFailure(res.message));
          toast.error(res.message);
          console.log("refghgh   ==== res", res);
          alert(res.message)

        }
      })
      .catch((err) => toast.error("Email Or Password incorrect"));
  };  