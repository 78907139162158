/** **************************** Import Types ****************************** */
import {
    ABOUTUS_REQUEST,
    ABOUTUS_SUCCESS,
    ABOUTUS_FAILURE,

} from "../types/aboutusTypes";

const initialState = {
    loading: false,
    aboutusResponse: "",
    error: "",
};

const aboutusReducer = (state = initialState, action) => {
    console.log("form about us api reducer", action.payload);
    switch (action.type) {
        case ABOUTUS_REQUEST:
            return {
                ...state,
                loading: true,
                aboutusResponse: "",
            };
        case ABOUTUS_SUCCESS:
            return {
                loading: false,
                aboutusResponse: action.payload.responseStatus,
                error: "",
            };
        case ABOUTUS_FAILURE:
            return {
                loading: false,
                aboutusResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default aboutusReducer;
