import React from "react";
import "./searchbar.css";
import { FiSearch } from "react-icons/fi";

const SearchBar = ({ handleChange, value }) => {
  return (
    <div class="search-box-page">
      {/* <input type="text" placeholder="Search" /> */}
      <input
        type="text"
        class="search-input-page"
        placeholder="Search..."
        onChange={(e) => handleChange(e)}
        value={value}
        // onChange={debouncedSearch}
      />
      <button class="search-btn-page">
        <FiSearch />
      </button>
      <div></div>
    </div>
  );
};

export default SearchBar;
