import { DELETE_ICON } from "../types/deleteiconType";
const initialState = {
    deleteItems: []
};
export const DeleteiconReducer = (state = initialState, action) => {
    console.log(action, "Im delete-icon");
    switch (action.type) {
        case DELETE_ICON:
            return {
                ...state,
                deleteItems: state.deleteItems.push(action.payload)
            };
        default:
            return state;
    }
}