import { SIDE_BAR } from "../types/sideBarTypes";
const initialState = {
    storeSideBarMinimizer: true,
};
export const sideBarReducer = (state = initialState, action) => {
    console.log(action, "Im minimizer");
    switch (action.type) {
        case SIDE_BAR:
            return {
                ...state,
                storeSideBarMinimizer: !state.storeSideBarMinimizer
            };
        default:
            return state;
    }
}