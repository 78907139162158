/** **************************** Import Types ****************************** */
import {
    NEWSFEED_REQUEST,
    NEWSFEED_SUCCESS,
    NEWSFEED_FAILURE,

} from "../types/newsFeedTypes";

const initialState = {
    loading: false,
    newsfeedResponse: "",
    error: "",
};

const NewsFeedReducer = (state = initialState, action) => {
    console.log("form NewsFeed api reducer", action.payload);
    switch (action.type) {
        case NEWSFEED_REQUEST:
            return {
                ...state,
                loading: true,
                newsfeedResponse: "",
            };
        case NEWSFEED_SUCCESS:
            return {
                loading: false,
                newsfeedResponse: action.payload.responseStatus,
                error: "",
            };
        case NEWSFEED_FAILURE:
            return {
                loading: false,
                newsfeedResponse: "error",
                error: action.payload,
            };

        default:
            return state;
    }
};

export default NewsFeedReducer;
