import React, { useState } from "react";
import SearchBar from "../Searchbar/SearchBar";
import { FiPlus, FiUpload } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";

import "./tableTop.css";

const TableTop = (props) => {
  const {
    searchBar,
    openDialog,
    addBtn,
    tabTitle,
    upload,
    handleFile,
    opneLink,
    backBtn,
    backToggle,
    UploadAdd,
    btnName2,
    btnName,
    dropDown,
    buldDownload,
    data,
    Value,
    svalue,
    handleChange,
    searchValue,
    addBtnProps,
    dataValue,
  } = props;

  const [selectedOption, setSelectedOption] = React.useState("null");
  return (
    <div className="container-fluid">
      <div className="card">
        <div className="table-head-bar">
          <div className="back-title">
            {backBtn ? (
              <i class="material-icons-round back-Arrow" onClick={backToggle}>
                arrow_back_ios
              </i>
            ) : null}
            {tabTitle ? <p className="title-header">{props.title}</p> : null}
          </div>
          <div className="d-flex justify-content-around pe-2">
            {searchBar ? (
              <SearchBar
                className="def-search"
                handleChange={handleChange}
                value={searchValue}
              />
            ) : null}
            &nbsp;&nbsp;
            <div className="top-div d-flex">
              {addBtnProps ? (
                <button
                  disabled={dataValue >= 1 ? true : false}
                  className="btn add-btn-all"
                  onClick={() => openDialog("add")}
                >
                  <i class="material-icons-round add-icon-tab">add</i>
                  &nbsp;{btnName ? props.btnName : "Add Configuration"}
                </button>
              ) : null}
              {addBtn ? (
                <button
                  className="btn add-btn-all"
                  onClick={() => openDialog("add")}
                >
                  <i class="material-icons-round add-icon-tab">add</i>
                  &nbsp;{btnName ? props.btnName : "Add Configuration"}
                </button>
              ) : null}
              {UploadAdd ? (
                <button
                  className="btn add-btn-all"
                  onClick={() => openDialog("add")}
                >
                  <FiUpload />
                  &nbsp;{btnName2 ? props.btnName2 : "Upload"}
                </button>
              ) : null}
              {buldDownload ? (
                <button
                  className="btn btn btn-success"
                  // onClick={opneLink}
                  onClick={() => opneLink(data)}
                  style={{ marginRight: "11px", height: "36px" }}
                >
                  <BsDownload />
                  &nbsp; Download
                </button>
              ) : null}
              {upload ? (
                <button className="btn add-btn-all" onClick={handleFile}>
                  <FiUpload />
                  &nbsp; Upload
                </button>
              ) : null}
              {dropDown ? (
                <div>
                  <select
                    className="select-box"
                    value={selectedOption}
                    onChange={(e) => {
                      setSelectedOption(e.target.value);
                      svalue(e.target.value);
                    }}
                  >
                    <option value={""}>Choose Test Module</option>
                    {Value}
                  </select>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableTop;
