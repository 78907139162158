const environmentList = [
  "http://localhost:8000", //local 0
  "https://staging-admin-api.qbace.in", //staging 1
  "https://admin-api.qbace.in", //production 2
];

export const env = 2; // Place your environment number heres

export const hostConfig = {
  CURRENT_URL: environmentList[env],
  WEB_URL: process.env.url,
  API_URL: `${environmentList[env]}/api/v1/`,
};
