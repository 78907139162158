import React from "react";
import "../src/assets/css/bootstrap.min.css";
import Preloader from "../src/components/Preloader";
import "./App.css";
import "./assets/css/style.css";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Signin";
import TableData from "./pages/Table";
// import ProtectedRoute from "./components/PrivateRoutes/ProtectedRoute"
//lazy Load
// const PageNotFound = React.lazy(() => import("./pages/Pagenot"));
function App() {
  //Container
  const Layout = React.lazy(() => import("./container/Layout"));
  const isAuthenticated = localStorage.getItem("token");

  // let routes = null;
  // if (!isAuthenticated) {
  //   routes = <Route exact path='/' component={Login} />
  // } else {
  //   routes = (
  //     <Switch>
  //       <Route path="/" name="Home" render={props => <Layout {...props} />} />
  //       <Route path="*" exact={true} component={PageNotFound} />

  //       {/* <Route component={PageNotFoundError} /> */}
  //     </Switch>
  //   );
  // }
  return (

    <>
      <Router>
        <React.Suspense fallback={<div><Preloader/></div>}>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route
              path="/"
              name="Home"
              render={(props) => <Layout {...props} />}
            />
            <Route path="/table" component={TableData} />
            <Route path="*" exact={true}/>
            {/* {routes} */}
          </Switch>
        </React.Suspense>
      </Router>
      <ToastContainer
        limit={1}
        autoClose={2000}
        transition={Zoom}
        pauseOnFocusLoss={false}
        newestOnTop
      />
    </>
  );
}

export default App;
